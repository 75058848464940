import LimitInputForm from "atoms/LimitInputForm";
import { Input, Text, View } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { AiFillPlusCircle } from "react-icons/ai";
import colors from "theme/colors";
import { numberWithDots } from "utils/helpers";
import PropTypes from "prop-types";

function LimitCurrentAndRequired({ labelKey, testID, sText, name, totalKey, limit, currencySymbol, requestLimits }) {
  const { t } = useTranslation("common");

  return (
    <View flexDirection="row" marginTop="16px">
      <View marginRight="4px" width="30%">
        <Text variant="xs" color={colors.neutral.darkMist}>
          {t(labelKey)}
        </Text>
        <Input isDisabled value={currencySymbol + numberWithDots(limit)} textAlign="right" />
      </View>

      <AiFillPlusCircle
        size="32px"
        style={{ alignSelf: "center", marginTop: "16px", marginLeft: "16px", marginRight: "16px" }}
      />
      <LimitInputForm
        testID={testID}
        sText={sText}
        name={name}
        currentValue={limit}
        defaultValue={requestLimits}
        total={totalKey}
        transTotalKey={`limitRequests.${totalKey}`}
      />
    </View>
  );
}

LimitCurrentAndRequired.propTypes = {
  labelKey: PropTypes.string.isRequired,
  testID: PropTypes.string.isRequired,
  sText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  totalKey: PropTypes.string.isRequired,
  limit: PropTypes.string.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  requestLimits: PropTypes.string.isRequired,
};

export default LimitCurrentAndRequired;
