import { HStack, VStack } from "native-base";
import { Routes, Route, useNavigate, Navigate, useLocation } from "react-router-dom";
import TopBar from "atoms/TopBar";
import routeData from "global/routes";
import { getStore } from "store/storeUtils";
import jwtDecode from "jwt-decode";
import React, { Suspense, useEffect } from "react";
import secureStore from "utils/secureStore";
import AmplitudeHelper from "utils/analytics";
import { SentryHelper } from "utils/performance/SentryHelper";
import { fetchMeApiCall, getEmployerConfig } from "api/useQueryCalls";
import ErrorFallBack from "pages/ErrorFallBack";
import useOnlineStatus from "utils/useOnlineStatus";
import ShowOnlineBanner from "atoms/ShowOnlineBanner";
import ModalAlerts from "atoms/ModalAlerts";
import { MODAL_ALERTS } from "global/constants";
import { canShowAccessAlert, canShowSuspendedAlert, forceLogoutOnWAS, getIfSDA } from "utils/utilities";
import { AMPLITUDE_EVENTSOURCES } from "utils/analytics/constants";
import { showComponents } from "pages/SingleCreate/utils";
import { IOB, TEAM } from "global/paths";

const NavBar = React.lazy(() => import("molecules/NavBar"));

export function modalAlertHandler(
  isSuccess,
  data,
  setModalAlert,
  setMeApiData,
  resetLoginData,
  navigate,
  employerConfigData,
) {
  if (isSuccess) {
    if (canShowSuspendedAlert(data, employerConfigData, false)) {
      setModalAlert({ ...MODAL_ALERTS.WS_MODAL, source: AMPLITUDE_EVENTSOURCES.team_page });
      secureStore.setItemAsync("hasViewedWS", true);
    }
    setMeApiData(data);
    forceLogoutOnWAS(data, resetLoginData, navigate);
  }
}

export function shouldRenderLandingRoute(employerConfigData) {
  if (employerConfigData?.isInitialOnboarding) return <Route path="/" element={<Navigate to={IOB} replace />} />;
  return <Route path="/iob" element={<Navigate to={TEAM} replace />} />;
}

export function getBaseRouteForNotFound(employerConfigData) {
  if (employerConfigData?.isInitialOnboarding === true) return IOB;
  const firstRoute = routeData.filter((item) => employerConfigData?.menu?.[0] === item.accessKey);
  return firstRoute[0]?.path;
}

export async function iobFirstTimeModalHandler(employerConfigData, setModalAlert) {
  if (employerConfigData && employerConfigData?.isInitialOnboarding && employerConfigData?.isFirstLogin) {
    const hasViewedFirstLogin = await secureStore.getItemAsync("hasViewedFirstLogin");
    if (!hasViewedFirstLogin) {
      setModalAlert({ ...MODAL_ALERTS.INITIAL_ONBOARDING, source: AMPLITUDE_EVENTSOURCES.team_page });
      await secureStore.setItemAsync("hasViewedFirstLogin", true);
    }
  }
  if (employerConfigData?.isEndOfInitialOnboarding === true) {
    setModalAlert({ ...MODAL_ALERTS.IOB_CONGRATULATIONS, source: AMPLITUDE_EVENTSOURCES.team_page });
    await secureStore.setItemAsync("hasViewedEndIOBModal", true);
    await secureStore.setItemAsync("hasViewedWS", true);
  }
}

export function shouldRenderRoutes(employerConfigData, isSda) {
  return routeData.map((route) => {
    if (!employerConfigData) return null;
    if (!isSda && route?.isSDA) return null;
    if (!showComponents(employerConfigData, route)) return null;
    if (!employerConfigData?.isInitialOnboarding) {
      const menusAccessible = employerConfigData.menu?.indexOf(route.accessKey);
      if (menusAccessible < 0) return null;
    }
    if (route.children) {
      return (
        <Route key={`${route?.id}-${route?.path}`} path={route?.path} element={route?.element}>
          {route?.children?.map((child) => (
            <Route key={`${child?.id}-${child?.path}`} path={child?.path} element={child?.element} />
          ))}
        </Route>
      );
    }
    return <Route key={`${route?.id}-${route?.path}`} path={route?.path} element={route?.element} />;
  });
}

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userData, setMeApiData, setModalAlert, resetLoginData, setEmployerDataConfig, employerConfigData } =
    getStore();

  const { data, isSuccess } = fetchMeApiCall();
  const { data: employerConfigApiData, isSuccess: isConfigDataSuccess } = getEmployerConfig();

  useEffect(() => {
    modalAlertHandler(isSuccess, data, setModalAlert, setMeApiData, resetLoginData, navigate, employerConfigApiData);
  }, [data, isSuccess, employerConfigApiData]);

  useEffect(() => {
    if (canShowAccessAlert(employerConfigData, location)) {
      setModalAlert({ ...MODAL_ALERTS.ACCESS_DENIED });
    }
  }, [employerConfigApiData, location]);

  useEffect(() => {
    iobFirstTimeModalHandler(employerConfigApiData, setModalAlert);
  }, [employerConfigApiData, isConfigDataSuccess]);

  useEffect(() => {
    (() => {
      const token = secureStore.getItemAsync("accessToken");
      const decodedToken = jwtDecode(token);
      AmplitudeHelper.setUserProperties({
        userId: decodedToken.userId,
        employerId: decodedToken.employerId,
        name: userData.userName,
      });
      SentryHelper.setUser(decodedToken.userId, decodedToken.employerId, userData.userName);
    })();
  }, []);

  useEffect(() => {
    if (isConfigDataSuccess) setEmployerDataConfig(employerConfigApiData);
  }, [isConfigDataSuccess]);

  const isSda = getIfSDA(employerConfigData);

  const isOnline = useOnlineStatus();

  const refreshPage = () => {
    navigate("/");
  };

  return (
    <VStack>
      <TopBar name={userData?.userName ? userData?.userName.split(" ")[0] : ""} />
      <ModalAlerts />
      <HStack height="92vh">
        {!isOnline && <ShowOnlineBanner refreshPage={refreshPage} />}
        <Suspense fallback={<div>Laoding....</div>}>
          <NavBar isSda={isSda} />
        </Suspense>
        <ErrorFallBack navigate={navigate}>
          <Routes>
            {shouldRenderRoutes(employerConfigData, isSda)}
            {shouldRenderLandingRoute(employerConfigData)}
            <Route path="*" element={<Navigate to={getBaseRouteForNotFound(employerConfigData)} replace />} />
          </Routes>
        </ErrorFallBack>
      </HStack>
    </VStack>
  );
};

export default Home;
