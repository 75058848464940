import { Center, Spinner } from "native-base";
import colors from "theme/colors";

const AppSpinner = () => {
  return (
    <Center dataTestid="center-container" justifyContent="center" alignItems="center" h="700px">
      <Spinner dataTestid="spinner" size="lg" color={colors.secondary.orchid} />
    </Center>
  );
};

export default AppSpinner;
