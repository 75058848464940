import dayjs from "dayjs";
import colors from "theme/colors";

export const removeNonNumericWhileTyping = (num = 0) => {
  return num?.toString().replace(/[^0-9]/g, "");
};

export const removeNonNumeric = (num = 0) => {
  const cleanedNum = num?.toString().replace(/[^0-9.]+/g, "");

  // Remove trailing .00 from the decimal part
  return cleanedNum?.replace(/\.00$/, "");
};

export const addCommasToNumberWithDots = (num) => {
  return num?.toString().replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, "$&.");
};

export const numberWithDots = (num) => {
  return `${num < 0 ? "-" : ""}${addCommasToNumberWithDots(removeNonNumericWhileTyping(num))}`;
};

export const addCommas = (num) => {
  const stringWithCommas = num?.toString().replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, "$&.");
  if (stringWithCommas) return stringWithCommas;
  return "0";
};

export const numberWithDotsAndEmptyCaseZero = (num) => {
  return `${num <= 0 ? "Rp0" : "Rp"}${addCommas(removeNonNumericWhileTyping(num))}`;
};

export const numberWithDotsAndNegativeCase = (num = 0) => {
  return `${num < 0 ? "Rp-" : "Rp"}${addCommas(removeNonNumeric(num))}`;
};

export const currencyFormatting = (num) => {
  return num <= 0 || !num ? "Rp0" : `Rp${num.toLocaleString()}`;
};

export const getName = (item, defaults) => {
  let found = defaults.findIndex((x) => x.data == item);
  if (found != -1) {
    return defaults[found];
  }
};

export const formatDateToISOWithUTCOffset = (date = "") => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = "00";
  const minutes = "00";
  const seconds = "00";
  const timeZoneOffset = "+00:00";

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timeZoneOffset}`;
};

export const getFormattedStartDate = (date = "") => {
  const selectedDate = dayjs(date);

  const fromDate = selectedDate.startOf("day").subtract(selectedDate.utcOffset(), "minutes");

  const formattedFromDate = fromDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  return formattedFromDate.toString();
};

export const getFormattedEndDate = (date = "") => {
  const selectedDate = dayjs(date);

  const toDateTime = selectedDate.endOf("day").subtract(selectedDate.utcOffset(), "minutes");

  const formattedToDateTime = toDateTime.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  return formattedToDateTime.toString();
};

export const getDifferenceBetweenObjects = ({ object1 = {}, object2 = {} }) => {
  let differences = {};
  Object.keys(object2).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(object2, key) && object1[key] !== object2[key] && !!object2[key]) {
      differences[key] = object2[key];
    }
  });
  return differences;
};

/**
Formats bytes into human-readable string.
@param {number} bytes - Number of bytes.
@param {number} [decimals=2] - Number of decimals to keep.
@param {number} [base=1024] - Base for size calculations (1024 for binary, 1000 for metric).
@returns {string} Formatted string.
*/
export const formatBytes = (bytes, decimals = 2, base = 1024) => {
  if (typeof bytes !== "number") {
    // console.error("Expected 'bytes' to be a number.");
    return "";
  }
  if (bytes === 0) return "0 Bytes";

  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const factor = Math.log(base);
  const index = Math.floor(Math.log(bytes) / factor);

  if (index < 0 || index >= sizes.length) {
    // console.error("The 'bytes' value is outside of the expected range.");
    return "";
  }

  return `${parseFloat((bytes / base ** index).toFixed(decimals))} ${sizes[index]}`;
};

/**
Truncates a string if its length exceeds 15 characters
@param {string} str - string to truncate.
@param {number} limit - maxlength of string
@returns {string} Formatted string.
*/
export const stringLimit = (str = "", limit = 15) => {
  if (typeof str !== "string") {
    return "";
  }
  return str.length > limit ? `${str.slice(0, limit)}...` : str;
};

/**
De-structures and gets the name of the employee from the employee data
@param {object} employeeData - data of the employee.
@returns {string} Formatted string.
*/
export const getEmployeeName = (employeeData) => {
  if (typeof employeeData !== "object" || Array.isArray(employeeData)) {
    return "";
  }
  const { employee = {} } = employeeData || {};
  return `${employee?.user?.firstName || ""}${employee?.user?.lastName || ""}`
    ? stringLimit(`${employee?.user?.firstName || ""} ${employee?.user?.lastName || ""}`)
    : stringLimit(`${employeeData?.firstName ?? ""} ${employeeData?.lastName ?? ""}`);
};

function getTxtColorForVerifiedTab(tabName, selectedTab) {
  return tabName.includes("rejectedTab") && selectedTab === tabName ? colors.error.darkRose : colors.black;
}

function getBorderColorForVerifiedTabUnselected(tabName, selectedTab) {
  return tabName.includes("rejectedTab") && selectedTab === tabName ? colors.primary.softCarnation : colors.black;
}

function getBGColorForVerifiedTab(tabName, selectedTab) {
  return tabName.includes("rejectedTab") && selectedTab === tabName ? colors.primary.softCarnation : "transparent";
}

function getTextColorForVerifiedTab(tabName, selectedTab) {
  return tabName.includes("verifiedTab") && selectedTab === tabName
    ? colors.variants.darkClover
    : getTxtColorForVerifiedTab(tabName, selectedTab);
}

function getBorderColorForVerifiedTab(tabName, selectedTab) {
  return tabName.includes("verifiedTab") && selectedTab === tabName
    ? colors.variants.softClover
    : getBorderColorForVerifiedTabUnselected(tabName, selectedTab);
}

function getBackgroundColorForVerifiedTab(tabName, selectedTab) {
  return tabName.includes("verifiedTab") && selectedTab === tabName
    ? colors.variants.softClover
    : getBGColorForVerifiedTab(tabName, selectedTab);
}

/**
returns the color code for the tab and text in the tab for the SD request page
@param {String} tabName - tab name
@param {String} colorType - color type. Options can be for background color, border color,text color
@returns {string} color code.
*/
export const getColorForSDRequestTab = ({
  tabName = "",
  colorType = "",
  selectedTab = "",
  shouldButtonsBeDisabled = false,
}) => {
  switch (colorType) {
    case "backgroundColor":
      return getBackgroundColorForVerifiedTab(tabName, selectedTab);

    case "borderColor":
      return getBorderColorForVerifiedTab(tabName, selectedTab);

    case "textColor":
      return getTextColorForVerifiedTab(tabName, selectedTab);
    case "buttonBackgroundColor":
      return shouldButtonsBeDisabled ? colors.neutral.mist : colors.secondary.orchid;

    case "buttonTextColor":
      return shouldButtonsBeDisabled ? colors.disabled.smoke : colors.neutral.cotton;

    case "transparentButtonBackgroundColor":
      return shouldButtonsBeDisabled ? colors.neutral.mist : "";

    case "redButtonBorderColor":
      return shouldButtonsBeDisabled ? colors.neutral.mist : colors.error.rose;

    case "redButtontextColor":
      return shouldButtonsBeDisabled ? colors.disabled.smoke : colors.error.rose;

    default:
      return "transparent";
  }
};

/**
returns whether the array is an array of strings or not
@param {Array} arr - array list
@returns {boolean} true/false.
*/
export const isArrayOfStrings = (arr) => {
  if (!Array.isArray(arr)) {
    return false; // Not an array
  }

  return arr.every((element) => typeof element === "string");
};

export const appendTimestampToFileName = (file) => {
  // Get the current Unix timestamp
  const timestamp = dayjs().format("DDMMYYYY-HHmmss");

  // Extract the file extension
  const fileExtension = file.name.split(".").pop();

  // Create a new filename with the timestamp
  const newFilename = `${file.name.split(".")[0]}_${timestamp}.${fileExtension}`;

  // Create a new File object with the new filename
  return new File([file], newFilename, { type: file.type });
};

export const isInitialOnboarding = (data) => {
  return data?.isInitialOnboarding;
};

export function setterForData(name, data, txnDates, filterData) {
  const updatedData = { ...data };

  if (name !== "") {
    updatedData.search = name;
  }

  if (txnDates?.startDate && txnDates?.endDate) {
    updatedData.fromTransactionDate = getFormattedStartDate(txnDates.startDate);
    updatedData.toTransactionDate = getFormattedEndDate(txnDates.endDate);
  }

  if (filterData?.fromTransactionAmount) {
    updatedData.fromTransactionAmount = filterData.minAmount;
  }
  if (filterData?.toTransactionAmount) {
    updatedData.toTransactionAmount = filterData.maxAmount;
  }
  if (filterData?.employeeGroup && filterData.employeeGroup.length > 0) {
    updatedData.employeeGroupName = filterData.employeeGroup;
  }
  if (filterData?.txnStatus && filterData.txnStatus.length > 0) {
    updatedData.transactionStatus = filterData.txnStatus.join(",");
  }
  if (filterData?.withdrawalPurpose && filterData.withdrawalPurpose.length > 0) {
    updatedData.transactionCategoryId = filterData.withdrawalPurpose.map((i) => i.id).join(",");
  }

  Object.assign(data, updatedData); // Merge changes into the original object
}
