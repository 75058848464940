import { Image, Text, VStack } from "native-base";
import { Logo } from "assets";
import styles from "./styles";

const Welcome = ({ t }) => (
  <VStack space={0}>
    <Image
      height={styles.breakpoints.logoSize}
      width={styles.breakpoints.logoSize}
      resizeMode="contain"
      source={Logo}
      alt="logo"
    />
    <Text variant="xxl-bold" style={styles.welcome}>
      {t("login.welcome")}
    </Text>
  </VStack>
);

export default Welcome;
