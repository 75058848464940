import colors from "theme/colors";

const styles = {
  image: {
    height: "100vh",
    width: "660px",
    resizeMode: "contain",
  },
  imageMobile: {
    width: "100vw",
    maxWidth: 500,
    height: "auto",
  },
  imageContainer: { backgroundColor: colors.secondary.orchid },
  copyright: { position: "fixed", bottom: 0, marginBottom: 20 },
};

export default styles;
