import { Hidden, Text, VStack, Button, FlatList } from "native-base";
import colors from "theme/colors";
import RouteButton from "atoms/RouteButton";
import routeData, { iobRoute } from "global/routes";
import { BsPower } from "react-icons/bs";
import { getStore } from "store/storeUtils";
import { useNavigate } from "react-router-dom";
import { CREATE_MODALS } from "global/constants";
import ErrorFallBack from "pages/ErrorFallBack";
import { forceLogout } from "utils/utilities";
import styles from "./styles";

export const getFilteredRouteData = (routeData, employerConfigData, iobRoute) => {
  if (!employerConfigData) return [];

  const filteredRoutes = routeData?.filter((route) => employerConfigData.menu?.includes(route.accessKey)) || [];

  if (employerConfigData.isInitialOnboarding) {
    return [iobRoute, ...filteredRoutes];
  }

  return filteredRoutes;
};

export const LogoutButton = () => {
  const { resetLoginData } = getStore();
  const navigate = useNavigate();
  const logout = () => forceLogout(true, {}, resetLoginData, navigate);

  return (
    <Button
      variant="rounded"
      bg={colors.secondary.orchid}
      w="90%"
      data-testid="logout"
      onPress={logout}
      borderWidth={1}
      borderColor={colors.neutral.cotton}
    >
      <Text variant="xs" color={colors.neutral.cotton}>
        <BsPower size="15px" style={{ marginBottom: "-3px" }} />
        {"  "}
        Logout
      </Text>
    </Button>
  );
};

export function shouldRenderItem(isSda, isFileUploading, showModalForDisableUpload) {
  return (item) => {
    if (!isSda && item.item?.isSDA) {
      return null;
    }

    return !isFileUploading ? (
      <RouteButton route={item.item} />
    ) : (
      <RouteButton
        route={item.item}
        isFileUploading={isFileUploading}
        showModalForDisableUpload={() => {
          showModalForDisableUpload(item?.item?.path);
        }}
      />
    );
  };
}

export function shouldRenderButtonList(setModal, modal, employerConfigData, isSda, isFileUploading) {
  return () => {
    const showModalForDisableUpload = (screenLink = "/") => {
      setModal({
        ...modal,
        type: CREATE_MODALS.fileIsUploading,
        screenLink,
      });
    };

    const filteredRouteData = getFilteredRouteData(routeData, employerConfigData, iobRoute);

    return (
      <FlatList
        data={filteredRouteData}
        renderItem={shouldRenderItem(isSda, isFileUploading, showModalForDisableUpload)}
      />
    );
  };
}

const NavBar = ({ isSda = false }) => {
  const navigate = useNavigate();
  const { isFileUploading, modal, setModal, employerConfigData } = getStore();

  const ButtonList = shouldRenderButtonList(setModal, modal, employerConfigData, isSda, isFileUploading);

  return (
    <Hidden till="lg">
      <VStack minW="268px" flex={0.15} style={styles.sideBar}>
        <VStack space={4} w="90%">
          <ErrorFallBack navigate={navigate} isNavBar>
            <ButtonList />
          </ErrorFallBack>
        </VStack>
        <VStack space={2} w="90%">
          <LogoutButton />
        </VStack>
      </VStack>
    </Hidden>
  );
};

export default NavBar;
