import colors from "theme/colors";

const styles = {
  welcome: { color: colors.black },
  breakpoints: {
    logoSize: [50, 50, 75, 100, 100],
  },
};

export default styles;
