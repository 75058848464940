import { refreshTokenHandler } from "api/networkUtils";
import { forceLogout } from "utils/utilities";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { getStore } from "store/storeUtils";
import secureStore from "utils/secureStore";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import AppSpinner from "atoms/AppSpinner";
import { useQueryClient } from "@tanstack/react-query";

export const checkForSameDayToken = async () => {
  try {
    const accessToken = secureStore.getItemAsync("accessToken");
    if (accessToken) {
      const decodedAccessToken = jwtDecode(accessToken);
      const accessTokenIssueAt = dayjs(decodedAccessToken.iat * 1000);
      const accessTokenIssueAtToday = accessTokenIssueAt.isSame(dayjs(), "day");
      if (!accessTokenIssueAtToday) refreshTokenHandler();
    }
  } catch (error) {
    // console.log('error);
  }
};

export async function refreshHandler(resetLoginData, navigate, setShowLoader) {
  try {
    const accessToken = secureStore.getItemAsync("accessToken");
    const refreshToken = secureStore.getItemAsync("refreshToken");

    if (accessToken) {
      const decodedAccessToken = jwtDecode(accessToken);
      const decodedRefreshToken = jwtDecode(refreshToken);

      const accessTokenExpiryAt = dayjs(decodedAccessToken.exp * 1000);
      const refreshTokenExpiryAt = dayjs(decodedRefreshToken.exp * 1000);

      const accessExpired = accessTokenExpiryAt.isBefore(dayjs());
      const refreshExpired = refreshTokenExpiryAt.isBefore(dayjs());

      if (accessExpired) {
        setShowLoader(true);
        Sentry.addBreadcrumb({ category: "info", message: `Refresh System - Access Token Expired`, level: "info" });
        if (refreshExpired) {
          Sentry.addBreadcrumb({
            category: "info",
            message: `Refresh System - Refresh Token Expired`,
            level: "info",
          });
          forceLogout(true, {}, resetLoginData, navigate);
        } else {
          Sentry.addBreadcrumb({
            category: "info",
            message: `Refresh System - Refreshing User`,
            level: "info",
          });
          await refreshTokenHandler();
        }
      } else checkForSameDayToken();
    }
  } catch (error) {
    Sentry.addBreadcrumb({
      category: "info",
      message: `Refresh System - Force Log out User`,
      level: "info",
    });
    forceLogout(true, {}, resetLoginData, navigate);
  } finally {
    setShowLoader(false);
  }
}

const RefreshSystem = ({ children }) => {
  const [showLoader, setShowLoader] = useState(true);
  const { resetLoginData, shouldForceLogout } = getStore();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    refreshHandler(resetLoginData, navigate, setShowLoader);
  }, []);

  const handleVisibilityChange = async () => {
    if (document.visibilityState === "visible") {
      refreshHandler(resetLoginData, navigate, setShowLoader).finally(() => {
        queryClient.invalidateQueries({ queryKey: ["fetch_me_api"] });
        queryClient.invalidateQueries({ queryKey: ["get_employer_config"] });
      });
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("focus", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (shouldForceLogout) forceLogout(true, {}, resetLoginData, navigate);
  }, [shouldForceLogout]);

  if (showLoader) return <AppSpinner />;
  return children;
};

export default React.memo(RefreshSystem);
