import { queryClient } from "api/clientProvider";
import ToastAlert from "atoms/ToastAlert";
import { EMPLOYER_STATUS_TYPE } from "global/constants";
import { routeToAccessKey } from "global/routes";
import AmplitudeHelper from "utils/analytics";
import { SentryHelper } from "utils/performance/SentryHelper";
import secureStore from "utils/secureStore";

/*
  To check for the error is type of array or not , 
  doing this to deal with multiple toast not  showing issue and displaying the first popup 
*/
export function getErrorType(err) {
  if (Array.isArray(err)) return err[0];
  return err;
}

// Function to force logout the user
export async function forceLogout(isSuccess, LogoutData, resetLoginData, navigate) {
  if (isSuccess && LogoutData) {
    secureStore.removeItemAsync("accessToken");
    secureStore.removeItemAsync("refreshToken");
    queryClient.clear();
    localStorage.clear();
    sessionStorage.clear();
    AmplitudeHelper.clearUserProperties();
    SentryHelper.clearUser();
    resetLoginData();
    navigate("/", { replace: true });
  }
}

export const showErrorToast = ({ error, toast, navigate = () => {}, resetLoginData = () => {} }) => {
  if (error && error.length > 0 && (error[0] === "GG-GEN-05" || error[0] === "GG-GEN-17")) {
    forceLogout(true, {}, resetLoginData, navigate);
  }
  toast.show({
    render: ({ id }) => (
      <ToastAlert
        toast={toast}
        id={id}
        title={`errorCodes.${getErrorType(error)}`}
        status="error"
        variant="solid"
        isClosable
      />
    ),
    placement: "top-right",
  });
};

export const showCustomToast = ({ title = "", status = "", style = {}, variant = "", toast }) => {
  return toast.show({
    render: ({ id }) => (
      <ToastAlert toast={toast} id={id} title={title} status={status} isClosable variant={variant} style={style} />
    ),
    placement: "top-right",
  });
};

export function checkForActiveHandler(formData, updatedData) {
  const newData = { ...updatedData };
  if (formData?.status === "Aktif" || formData?.status === "active") {
    newData.suspendedReason = undefined;
    newData.suspensionCategory = undefined;
  }
  return newData;
}

export function canShowSuspendedAlert(data, employerConfigData, pass = true) {
  // check from the local store if the previous status was changed based on that show the alert
  if (employerConfigData) {
    if (employerConfigData?.isInitialOnboarding === true) return false;
    if (employerConfigData?.isEndOfInitialOnboarding === true) return false;
    const hasViewedWS = secureStore.getItemAsync("hasViewedWS");
    return (data && data.status === EMPLOYER_STATUS_TYPE.WITHDRAW_SUSPENDED && !hasViewedWS) || pass;
  }
}

export function canShowAccessAlert(employerConfigData, location) {
  if (employerConfigData?.menu && !employerConfigData?.isInitialOnboarding) {
    return employerConfigData?.menu?.indexOf(routeToAccessKey[location.pathname]) < 0;
  }
  return false;
}

export function forceLogoutOnWAS(data, resetLoginData, navigate) {
  if (
    data?.status === EMPLOYER_STATUS_TYPE.WITHDRAW_ACCURAL_SUSPENDED ||
    data?.status === EMPLOYER_STATUS_TYPE.INACTIVE
  ) {
    forceLogout(true, {}, resetLoginData, navigate);
  }
}

export const getNameForFilters = (arr, defaults) => {
  if (arr.length == defaults.length) return "Semua";
  let names = arr.map((item) => item.name);
  return names.join(",");
};

export function getIfSDA(employerConfigData) {
  return (
    employerConfigData?.menu?.includes("SDA") ||
    employerConfigData?.menu?.includes("SDO") ||
    employerConfigData?.menu?.includes("SD")
  );
}
